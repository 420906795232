'use strict';

document.addEventListener('DOMContentLoaded', function () {

    var bMaps = document.querySelector('.business-maps'),
        bMapsExists = typeof(bMaps) != 'undefined' && bMaps != null;

    if (bMapsExists) {
        getMarkersData();
    };
});

class BusinessMaps {

    constructor(data) {
        self = this;
        self.data = data;

        self.tabs = document.querySelectorAll('.business-maps__tab');
        self.views = document.querySelectorAll('.business-maps__view');

        self.markers = document.querySelectorAll('.business-maps__object');
        self.popup = {
            itself: document.querySelector('.business-maps__popup'),
            close: document.querySelector('.business-maps__popup-close'),
            header: document.querySelector('.business-maps__popup-header span'),
            imageWrap: document.querySelector('.business-maps__popup-image'),
            image: document.querySelector('.business-maps__popup-image img'),
            body: document.querySelector('.business-maps__popup-body'),
        };
    }

    init() {
        self.mapEvents();
        self.switchMaps();
        self.popupEvents();
    }

    switchMaps() {
        var _loop2 = function _loop2(i) {
            var tab = self.tabs[i];

            tab.addEventListener('click', function() {
                self.toggleClass(self.tabs, i);
                self.toggleClass(self.views, i);
            });
        };

        for (var i = 0; i < self.tabs.length; i++) {
            _loop2(i);
        };
    }

    mapEvents() {
        var _loop = function _loop(i) {
            var marker = self.markers[i];

            marker.addEventListener('click', function (e) {
                e.stopPropagation();

                var markerID = this.getAttribute('data-id');

                self.clearPopupData();
                self.setPopupData(markerID);
                self.showPopup();
            });
        };

        for (var i = 0; i < self.markers.length; i++) {
            _loop(i);
        };
    }

    popupEvents() {
        self.popup.close.addEventListener('click', function () {
            self.hidePopup();
        });

        document.addEventListener('click', function (e) {
            var outsideClick = !self.popup.itself.contains(e.target),
                active = self.popup.itself.classList.contains('active');

            if (outsideClick && active) {
                self.hidePopup();
            }
        });
    }

    showPopup() {
        self.popup.itself.classList.add('active');
    }

    hidePopup() {
        self.popup.itself.classList.remove('active');
    }

    clearPopupData() {
        while (self.popup.body.firstChild) {
            self.popup.body.removeChild(self.popup.body.firstChild);
        };
    }

    setPopupData(id) {
        var currentData = self.data[id];

        self.popup.header.innerHTML = currentData.title;

        if (currentData.image.show == true) {
            self.popup.image.setAttribute('src', currentData.image.src);
            self.popup.imageWrap.style.display = 'block';
        } else {
            self.popup.imageWrap.style.display = 'none';
        };

        if (currentData.desc.length > 0) {
            for (var i = 0; i < currentData.desc.length; i++) {
                var data = currentData.desc[i];

                self.appendRow(data);
            };
        };
    }

    appendRow(data) {
        var row = document.createElement('div'),
            text = document.createElement('div'),
            title = document.createElement('div');

        row.className = 'business-maps__popup-row';
        text.className = 'business-maps__popup-text';
        title.className = 'business-maps__popup-title';

        if (data.name != undefined) {
            title.innerHTML = data.name;
            row.appendChild(title);
        };
        if (data.value != undefined) {
            text.innerHTML = data.value;
            row.appendChild(text);
        };

        self.popup.body.appendChild(row);
    }

    toggleClass(element, index) {
        for (var i = 0; i < element.length; i++) {
            element[i].classList.remove('current');
        };
        element[index].classList.add('current');
    };
};

var getMarkersData = function () {
    var http = new XMLHttpRequest(),
        url = '../business/maps-objects.json';

    http.open('GET', url, true);

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            try {
                var data = JSON.parse(http.responseText);
            } catch (err) {
                console.log(err.message + ' in ' + http.responseText);
                return;
            };

            var markersData = new BusinessMaps(data).init();
        };
    };

    http.send();
};